import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_es from './translations/es/common.json';
import common_en from './translations/en/common.json';
import { ParallaxBlock } from 'lc-react-essentials';
// Assets
import clean_1 from './assets/img/jpeg/clean_1.jpg';

const App = lazy(() => import('./App'));

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'es', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    es: {
      common: common_es,
    },
  },
});

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <ParallaxBlock
          key='parallax-block--home'
          idBlock='home'
          bgImgBlock={`linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(${clean_1})`}
        />
      }
    >
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
